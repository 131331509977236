@import './assets/fonts/index.css';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import '~@angular/material/theming';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import '~swiper/swiper.scss';


/*
* Custom Styles
*/

html {}

@supports (font-variation-settings: normal) {
  html { font-family: "Inter var", "system-ui", sans-serif; }
}

.bg-gradient-offer {
  background-image: linear-gradient(to right bottom, #34a8e0, #0093e2, #007ce1, #0063dc, #0046cf);
}

.translate-center {
  top: 54%;
  left: 50%;
  transform: translateY(-54%) translateX(-50%);
}

.cc-revoke.cc-bottom {
  visibility: hidden !important;
}

.cc-window.cc-right {
  z-index: 999999;
}

.expander {

  .expander__toggle {
    cursor: pointer;
  }

  .expander__content {
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 0;
    transition: max-height 255ms ease-in-out;
  }

  .expander__toggle__icon {
    pointer-events: none;
    transform: rotate(0);
    transition: transform 255ms ease-in-out;
  }
}

.expander--open {

  .expander__content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
    transition: max-height 255ms ease-in-out;
  }

  .expander__toggle__icon {
    transform: rotate(180deg);
    transition: transform 255ms ease-in-out;
  }
}

.wysiwyg {

  p,
  ul,
  ol,
  a {
    font-size: .875rem;
    color: #505763;
  }

  a {
    color: #33a8e0;

    &:hover {
      color: #f39100;
    }
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-left {
    text-align: left;
  }

}

.wysiwyg--dialog {
  p,
  ul,
  ol,
  a {
    font-size: 1rem;
    color: #fff;
  }

  a {
    color: #fff;

    &:hover {
      color: #f39100;
    }
  }
}

.wysiwyg--chat {
  p,
  ul,
  ol,
  a,
  table {
    font-size: .875rem;
    color: #505763;
  }

  ul,
  ol {
    padding-left: 1.1rem;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  h1 {
    font-size: .875rem;
    font-weight: 700;
  }

  h2 {
    font-size: .875rem;
    font-weight: 600;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: .875rem;
    font-weight: 500;
  }

  blockquote {
    background-color: #efefef;
    padding: .5rem;
    border-radius: 4px;
    border-left: 4px solid #33a8e0;
    font-size: .75rem;
  }

  code {
    display: inline-block;
    background-color: #efefef;
    padding: .5rem;
    border-radius: 4px;
    font-size: .75rem;
    font-family: 'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace';
  }

  hr {
    height: 1px;
    display: block;
    width: 100%;
    border: 0;
    margin: .5rem 0;
    background-color: #efefef;
  }

  table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
  }
  thead th {
    background-color: #DDEFEF;
    border: solid 1px #DDEEEE;
    color: #336B6B;
    padding: 10px;
    text-align: left;
    text-shadow: 1px 1px 1px #fff;
  }
  tbody td {
    border: solid 1px #DDEEEE;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px #fff;
  }
}

.wysiwyg--cards {

  p,
  ul,
  ol,
  a {
    font-size: .875rem;
    color: #8E8E8E;
  }

}

.wysiwyg--lists {

  p,
  ul,
  ol,
  a {
    font-size: .75rem;
    color: #8E8E8E;
  }

}

.wysiwyg--contest {
  p,
  ul,
  ol,
  a {
    font-size: 1rem;
    color: #fff;
    line-height: 1.25;
  }
}

.wysiwyg--content,
.wysiwyg--partner {

  ul {
    list-style-type: disc;
    padding-left: 1.1rem;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.1rem;
  }
}

.embed {

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


}

.swiper-slide {
  height: auto;
}

.hyphens {
  hyphens: auto;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.border-hider {
  border: 1px solid red;
}

.mat-dialog-container,
.mat-menu-panel {
  border-radius: 18px !important;
}

.dialog-contest {
  .mat-dialog-container {
    border-radius: 18px !important;
    padding: 0 !important;
  }
}
